<template>
  <div class='container'>
    <!-- <van-nav-bar title="用户订单" left-arrow fixed placeholder @click-left="close" /> -->
    <div v-if="tableData.length>0">
      <div>
        <div class="con" v-for="(item,index) in tableData" :key="index">
          <div class="marDan">{{item.orderTime}}</div>
          <div class="fr-b-c marDan">
            <div class="fr-c">
              {{item.yzOrderNo}}
              <el-button class="btn" icon="el-icon-copy-document
          " circle @click="copy(item.yzOrderNo)"></el-button>
            </div>
            <div style="color: red;">{{item.orderStatus}}</div>
          </div>
          <div class="marDan">
            所属店铺：{{item.storeName}}
          </div>

          <div class="goods" v-for="(items,indexs) in item.goodsList" :key="indexs">
            <img class="goodsImg" :src="items.imgUrl" alt="">
            <div class="flex-1">
              <div>{{items.name}}</div>
              <div style="text-align: right;">x{{items.num}}</div>
            </div>
          </div>

          <div class="flooter">
            <div>总共{{item.goodsList.length}}件商品，实收（含运费）：￥{{item.payment}}</div>
          </div>
        </div>
      </div>
      <el-pagination style="text-align: right;" :pager-count="5" background layout="prev, pager, next" :total="totalSize" @current-change="changepage">
      </el-pagination>
    </div>
    <el-empty v-else></el-empty>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import Clipboard from 'clipboard';
import homeManager from "@/request/homeManager.js";
import { set_config } from '@/tools/sdk-set-config.js'
export default {
  mixins: [set_config],
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      requst: {
        module: '',
        url: '',
      },
      query: {},
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      totalSize: 0,
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  // 方法集合
  methods: {
    // 初始化刷新页面数据
    async getInitRefreshPageInfo () {
      this.pageOrder();  //获取订单数据
    },

    async pageOrder () {
      try {
        let { code, data } = await homeManager.pageOrder({ pageSize: this.pageSize, currentPage: this.currentPage, custId: this.$store.getters.custId })
        if (code == 200) {
          this.tableData = data.dataList
          this.totalSize = data.totalSize
        }
      } catch (error) {
        //
      }
    },
    copy (val) {
      let clipboard = new Clipboard(".btn", {
        text: (trigger) => {
          //返回字符串
          return val;
        },
      });
      clipboard.on("success", (e) => {
        //复制成功
        this.$toast.success('复制成功')
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        //复制失败
        this.$toast.fail('复制失败');
        clipboard.destroy();
      });
    },
    //改变页码
    changepage (val) {
      this.currentPage = val
      this.pageEntry()
    },
    close () {
      this.$router.go(-1);
    },
  },
  // 生命周期 - 创建之前
  beforeCreate () { },
  // 生命周期 - 挂载之前
  beforeMount () { },
  // 生命周期 - 更新之前
  beforeUpdate () { },
  // 生命周期 - 更新之后
  updated () { },
  // 生命周期 - 销毁之前
  beforeDestroy () { },
  // 生命周期 - 销毁完成
  destroyed () { },
  //  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated () { },
}
</script>

<style scoped>
.container {
  min-height: 100vh;
  background: #f7f7f7;
  padding: 20px 20px;
  box-sizing: border-box;
}

.header {
  margin: 20px 0;
}

.header span {
  font-size: 30px;
  font-weight: 600;
}

.btn {
  font-size: 20px;
  margin-left: 10px;
}

.con {
  padding: 20px;
  margin-bottom: 20px;
  background: #fff;
  box-sizing: border-box;
  font-size: 24px;
  border-bottom: 1px solid #e5e5e5;
}

.con:last-child {
  border-bottom: none;
}

.marDan {
  margin-bottom: 10px;
}

.goods {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.goodsImg {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin-right: 20px;
}

.flooter {
  display: flex;
  justify-content: flex-end;
}
</style>